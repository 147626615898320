<template>
	<div>
		
		<stepper :stepIn="stepIn" :steps="[$t('csvxlsx_1')[0], $t('csvxlsx_1')[1], $t('csvxlsx_1')[2]]" color="green"></stepper>
		
		<div class="col-12">
			<div class="row" v-if="stepIn == 'step1'">
				<div class="col-12 col-sm-8 col-md-6 pt-4 pb-4 mx-auto uploadWrapper">
					<div id="imageUploadForm" :class="['imageUploadForm pt-3 green', loadingFile ? 'loading' : '', loadedFile ? 'loaded' : '']">
						<span class="helpText" id="helpText">{{ $t('csvxlsx_2')[0] }}</span>
						<input type="file" id="fileUpload" @change="selectFile" class="uploadButton" accept=".xlsx,.csv" />
						<div id="uploadedImg" class="uploadedImg" style="background-image:url('/img/sources/Sheets.png');">
							<span class="unveil"></span>
						</div>
						<span class="pickFile">
							<button @click="uploadFile" class="btn btn-dark btn-lg"><i class="fa fa-file-text-o"></i> {{ $t('csvxlsx_2')[1] }}</button>
						</span>
					</div>
					<div class="row mt-3" v-if="loadedFile">
						<div class="col-6 my-auto">
							<button class="btn btn-secondary" @click="nextStep('step1')">
								<i class="fa fa-undo"></i> {{ $t('csvxlsx_2')[2] }}
							</button>
						</div>
						<div class="col-6 text-right my-auto">
							<button class="btn btn-info" @click="nextStep('step2')">{{ $t('csvxlsx_2')[3] }} <i class="fa fa-chevron-right"></i></button>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="stepIn == 'step2'">
				<div class="col-12 col-sm-8 col-md-8 pt-4 pb-4 mx-auto">
					<div>
						<div class="form-group">
							<label for="ListName">{{ $t('csvxlsx_3')[0] }}</label>
							<input type="text" :class="['form-control', ListName.trim() == '' ? 'is-invalid' : '']" 
							id="ListName" placeholder="List Name" v-model.trim="ListName" />
							<small id="emailHelp" class="form-text text-muted">{{ $t('csvxlsx_3')[1] }}</small>
						</div>
						<div class="row mb-3">
							<div class="col-4">
								<b>{{ $t('csvxlsx_3')[2] }}</b>
							</div>
							<div class="col-2 my-auto text-center"></div>
							<div class="col-6 pl-0">
								<b>{{ $t('csvxlsx_3')[3] }}</b><!-- Field -->
							</div>
						</div>
						<div class="row pb-2" v-for="(item, key) in MappingFields" v-bind:key="`field-${key}`">
							<div class="col-4" v-html="$t('csvxlsx_4', {label:item.label})"></div>
							<div class="col-2 my-auto">
								<i class="fa fa-arrows-h fa-lg-md"></i>
								<!--i class="fa fa-long-arrow-right"></i-->
							</div>
							<div :class="[item.selected == 'custom_field' ? 'col-3' : 'col-6', 'pl-0']">
								<select class="form-control mapping-select" @change="selectMappingField(item)" v-model="item.selected">
									<option value=""> {{ $t('csvxlsx_5')[0] }} </option>
									<option v-for="(option, id) in fieldsArray" 
									v-bind:key="`option-${id}`" 
									v-text="option.title" :value="option.field" 
									:disabled="option.selected && option.field != 'custom_field'"></option>
								</select>
							</div>
							<div class="col-3 pl-0" v-if="item.selected == 'custom_field'">
								<!--input type="text" class="form-control" placeholder="title field" v-model="item.custom" /-->
								<auto-complete :items="CustomFields" :placeholder="$t('csvxlsx_5')[1]" @input="customInput(item)" v-model="item.custom" :defaultData="item.custom"></auto-complete>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-6 my-auto">
							<button class="btn btn-dark" @click="nextStep('step1')"><i class="fa fa-chevron-left"></i> {{ $t('csvxlsx_6')[0] }}</button>
						</div>
						<div class="col-6 text-right my-auto">
							<button class="btn btn-success" @click="saveFile()" :disabled="processing">
								<i :class="['fa', processing ? 'fa-cog fa-spin' : 'fa-cogs']"></i> {{ $t('csvxlsx_6')[1] }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="stepIn == 'step3'">
				<div class="col-12 col-sm-8 col-md-8 pt-4 pb-4 mx-auto">
					<div class="alert alert-success" role="alert">
						<h5 class="alert-heading">{{ $t('csvxlsx_6')[2] }}</h5>
						<p class="m-0" v-html="$t('csvxlsx_7', {count:arrayDataFile.length})"></p>
						<!--hr>
						<small class="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</small-->
					</div>
					<div class="text-center">
						<!--router-link :to="{name: 'dashboard'}"><i class="fa fa-bar-chart"></i> Dashboard</router-link>
						<span> | </span-->
						<router-link :to="{name: 'leads'}"><i class="fa fa-angle-left"></i> {{ $t('csvxlsx_8') }}</router-link><!-- leads/:list -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Stepper, AutoComplete } from '@/components'
	import XLSX from 'xlsx';
	export default {
		name: 'CsvXLSX',
		components: {
			Stepper,
			AutoComplete
		},
		mounted() {
			document.getElementById("uploadedImg").addEventListener('animationend', function() {} );
			this.GetConfig()
		},
		data() {
			return {
				// 
				loadingFile	: false,
				loadedFile	: false,
				stepIn		: 'step1',
				
				fileData	: {},
				ListName	: '',
				processing	: false,
				fieldsArray	: [
					{ field: 'full_name',  	title: this.$t('csvxlsx_9')[0], selected: false	},
					{ field: 'first_name', 	title: this.$t('csvxlsx_9')[1], selected: false	},
					{ field: 'last_name',  	title: this.$t('csvxlsx_9')[2], selected: false	},
					{ field: 'email',     	title: this.$t('csvxlsx_9')[3], selected: false	},
					{ field: 'phone',     	title: this.$t('csvxlsx_9')[4], selected: false	},
					{ field: 'status',		title: this.$t('csvxlsx_9')[5],	selected: false },
					{ field: 'generated_at',title: this.$t('csvxlsx_9')[6],	selected: false },
					{ field: "custom_field",title: this.$t('csvxlsx_9')[7], selected: false },
				],
				
				CustomFields	: [],
				
				MappingFields	: [],
				arrayDataFile	: [],
			}
		},
		computed : {
			
		},
		methods: {
			
			GetConfig() {
				
				let that 	  = this
				let request = { url: '/fields', method: 'GET', params: {} }
				that.$store.state.loading = true
				that.$store.dispatch('requests', request).then((response) => {
					
					let CustomFields	= response.data.custom_fields
					that.CustomFields	= CustomFields
					that.$store.state.loading = false
					
				}).catch(( {response} ) => {
					that.$store.state.loading = false
					that.ErrorHandler(response)
				} )
				
			},
			
			customInput(  ) { // item
				
			},
			
			selectMappingField(element) {
				if( element.oldValue != '' ) {
					this.fieldsArray.forEach( function(item) {
						if ( item.field == element.oldValue ) {
							item.selected = false
							item.oldValue = ''
						}
					} )
				}
				this.fieldsArray.forEach( function(item) {
					if( element.selected == item.field ) {
						item.selected = true
						element.oldValue = element.selected
					}
				} )
			},
			
			uploadFile() {
				document.getElementById("fileUpload").click()
			},
			
			nextStep(step) {
				let that = this
				if( step == 'step1' ) {
					that.loadingFile = false
					that.loadedFile  = false
					setTimeout( function() {
						document.getElementById('fileUpload').value= null;
					}, 1000 )
				}
				that.stepIn = step
			},
			
			selectFile(e) {
				let that 	= this
				var files 	= e.target.files;
				
				// application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
				// application/vnd.ms-excel
				
				that.loadingFile  = true
				// that.loadedFile   = false
				// that.arrayDataFile	  = {}
				that.MappingFields= []
				// that.fileData 	  = {}
				that.fileData 	  = files[0]
				that.ListName 	  = that.fileData.name
				
				that.fieldsArray.forEach( function(col) {
					col.selected = false
				} )
				
				var reader = new FileReader();
				reader.onload = function(e) {
					var data = new Uint8Array(e.target.result);
					var workArray = XLSX.read(data, {type: 'array', cellDates:true});
					let sheetName = workArray.SheetNames[0]
					/* DO SOMETHING WITH workArray HERE */
					let worksheet = workArray.Sheets[sheetName];
					
					// that.arrayDataFile	= XLSX.utils.sheet_to_json(worksheet)
					that.arrayDataFile	= XLSX.utils.sheet_to_json(worksheet,{defval:""})
					
					let fields = Object.keys(that.arrayDataFile[0])
					fields.forEach( function(item, id) {
						that.MappingFields.push({ field: item, label: item, selected: '', oldValue: '', custom: item })
						
						let fieldValue = item.toLowerCase()
						that.fieldsArray.forEach( function(col) {
							
							if( !col.selected && col.field != "custom_field" ) {
								if( fieldValue == col.field || fieldValue.includes(col.title.toLowerCase()) || fieldValue.toLowerCase().includes(col.field) ) {
									col.selected = true
									that.MappingFields[id].selected = col.field
									that.MappingFields[id].oldValue = col.field
								} else if ( 
									(fieldValue == 'name' && col.field == 'full_name')
									||  (fieldValue.includes('first') && fieldValue.includes('name') && col.field == 'first_name' )
									||  (fieldValue.includes('last') && fieldValue.includes('name') && col.field == 'last_name' )
								) {
									col.selected = true
									that.MappingFields[id].selected = col.field
									that.MappingFields[id].oldValue = col.field
								}
							}
							if( that.MappingFields[id].selected == '' ) {
								that.MappingFields[id].selected = 'custom_field'
							}
							
						} )
						
					} );
					
				};
				
				// that.loadedFile = true
				setTimeout( function() {
					that.loadedFile = true
				}, 3500 )
				
				reader.readAsArrayBuffer(that.fileData);
			},
			
			saveFile() {
				let that = this
				
				let stopProcess = true
				that.fieldsArray.forEach( function(col) {
					if( col.selected ) {
						stopProcess = false
					}
				} )
				if(stopProcess) {
					that.ToastNotify('warning', 'Mapping fields', 'Please map your fields')
					return false
				}
				
				if( that.ListName.trim() != "" ) {
					that.processing = true
					
					let fileData = []
					
					fileData.push({
						name		:that.fileData.name,
						size		:that.fileData.size,
						type		:that.fileData.type,
						lastModified:that.fileData.lastModified,
						lastModifiedDate:that.fileData.lastModifiedDate,
					})
					// webkitRelativePath: ""
					
					let url  	= 'csv/save'
					let method  = 'POST'
					let data 	= { mappingFields: that.MappingFields, fileData:fileData, ListName:that.ListName.trim(), arrayDataFile:that.arrayDataFile }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then(() => { // response
						
						that.processing = false
						that.stepIn 	= 'step3'
						that.$store.dispatch('SidebarCounter')
						
					}).catch(( {response} ) => {
						that.ErrorHandler(response)
						that.processing = false
						that.stepIn 	= 'step3'
					} )
				} else {
					that.ToastNotify('warning', 'List name', 'List name cannot be empty')
				}
				
			}
			
		},
		created() {
			
		}
	}
</script>

<style lang="scss" scope>
	
	
	/* --------  Mapping Style  -------- */
	.fa-lg-md {
		font-size: 26px !important;
	}
	select.mapping-select option[disabled] {
		font-weight: bold;
		background-color: #ccc;
		color: #343a40;
		cursor: not-allowed;
	}
	/* --------  Mapping Style  -------- */
	
	.uploadWrapper {
		
		.imageUploadForm {
			
			box-shadow: 0px 0px 4px #404040 /* 909090 */;
			height: 400px;
			position: relative;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: flex-end;
			align-items: flex-end;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			
			&.green {
				background: #1c8b55 /* #6E95F7 */;
				.helpText {
					color: white;
					&:after {
						border: 4px dashed white;
					}
				}
				.pickFile {
					background: white;
				}
			}
			&.white {
				background: #ffffff;
				.helpText {
					color: #404040;
					&:after {
						border: 4px dashed #404040;
					}
				}
				.pickFile {
					background: #404040;
				}
			}
			
			&.blue {
				background: #2196f3;
				.helpText {
					color: white;
					&:after {
						border: 4px dashed white;
					}
				}
				.pickFile {
					background: white;
				}
			}
			
			.helpText {
				display: block;
				width: 100%;
				height: 100%;
				text-align: center;
				font-size: 30px;
			}
			
			.helpText:after {
				content: '\f093';
				font-family: 'FontAwesome';
				font-size: 150%;
				
				display: -webkit-flex;
				display: flex;
				-webkit-align-items: center;
				align-items: center;
				-webkit-justify-content: center;
				justify-content: center;
				margin: 4% auto auto auto;
				width: 50%;
				height: 50%;
			}
			
			.pickFile {
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				height: 25%;
				width: 100%;
				display: -webkit-flex;
				display: flex;
				-webkit-align-items: center;
				align-items: center;
				-webkit-justify-content: center;
				justify-content: center;
			}
			
			.uploadButton {
				position: absolute;
				top: 62px;
				left: 120px;
				width: 52%;
				height: 52%;
				opacity: 0;
				cursor: pointer;
				z-index: 10;
			}
			&.loading .helpText {
				font-size: 0;
			}
			&.loading .helpText:before {
				font-size: 30px;
				content: 'Uploading...';
			}
			&.loading .helpText:after {
				display: none;
			}
			&.loading {
				.uploadedImg {
					/* background-image:url("/img/Sheets.png"); */
					background-size:55%;
					position: absolute;
					bottom: 12.5%;
					left: 12.5%;
					width: 75%;
					height: 65%;
					background-repeat: no-repeat;
					background-position: center center;
					
					-moz-transition: opacity ease-out;
					-o-transition: opacity ease-out;
					-webkit-transition: opacity ease-out;
					transition: opacity ease-out;
				}
				&.green .uploadedImg {
					border: 4px dashed white;
				}
				&.blue .uploadedImg {
					border: 4px dashed white;
				}
				&.white .uploadedImg {
					border: 4px dashed #404040;
				}
			}
			&.loading .unveil {
				&.green {
					background: #1c8b55;
				}
				&.white {
					background: white;
				}
				
				&.blue {
					background: #2196f3;
				}
				
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				-moz-animation: toTop 4s forwards;
				-webkit-animation: toTop 4s forwards;
				animation: toTop 4s forwards;
			}
			&.loading .unveil:after {
				content: '';
				position: absolute;
				left: -3%;
				bottom: 0;
				height: 10px;
				width: 106%;
				background: #FFD16E;
				border-radius: 5px;
			}
			&.loading .pickFile, .imageUploadForm.loading .uploadButton {
				display: none;
			}
			
			&.loading.loaded {
				height: 200px;
			}
			&.loading.loaded .uploadedImg {
				opacity: 0;
			}
			&.loading.loaded .uploadButton {
				display:none;
			}
			&.loading.loaded .helpText:before {
				content: 'Upload Complete!';
			}
			&.loading.loaded {
				.helpText:after {
					display: block;
					opacity: 0;
					font-size: 0;
					line-height: 100px;
					-moz-animation: fadeIn 0.4s forwards;
					-webkit-animation: fadeIn 0.4s forwards;
					animation: fadeIn 0.4s forwards;
					content: '\f058';
					
					margin-top: 2%;
					border-width: 0;
				}
				&.green .helpText:after {
					color: white;
				}
				&.white .helpText:after {
					color: #404040;
				}
				
				&.blue .helpText:after {
					color: white;
				}
			}
			
			@-moz-keyframes toTop {
				to {
					height: 0;
				}
			}
			@-webkit-keyframes toTop {
				to {
					height: 0;
				}
			}
			@keyframes toTop {
				to {
					height: 0;
				}
			}
			@-moz-keyframes fadeIn {
				to {
					opacity: 1;
					font-size: 90px;
				}
			}
			@-webkit-keyframes fadeIn {
				to {
					opacity: 1;
					font-size: 90px;
				}
			}
			@keyframes fadeIn {
				to {
					opacity: 1;
					font-size: 90px;
				}
			}
			
		}
		
		i.fa-chevron-right {
			vertical-align: inherit;
		}
		
	}
	
</style>