<template>
	<div class="container-fluid">
		<h4 class="c-grey-900 mT-10 mB-30">{{ $t('lstsettings_1') }}</h4>
		<div class="row">
			<div class="col-md-12">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					
					<h4 class="c-grey-900 mB-20">
						<router-link :to="{name: 'lists'}">
							<i class="fa fa-arrow-circle-o-left cur-p"></i>
						</router-link> {{ $t('lstsettings_2') }}
					</h4>
					
					<div class="col-12 col-sm-10 col-md-10 col-lg-10 py-4 mx-auto">
						
						<div class="row">
							<div class="col-12 text-right">
								<button class="btn btn-primary" @click="SaveChanges" :disabled="Loading">
									<i :class="['fa', Loading ? 'fa-cog fa-spin' : 'fa-save']"></i> {{ $t('lstsettings_3')[0] }}
								</button>
							</div>
						</div>
						<div class="row label-weight">
							<div class="col-6">
								<div class="form-group">
									<label for="ListName">{{ $t('lstsettings_3')[1] }}</label>
									<input type="text" :class="['form-control', selectedList.name == '' ? 'is-invalid' : '']" 
									id="ListName" :placeholder="$t('lstsettings_3')[2]" v-model="selectedList.name" />
									<!--small class="form-text text-muted">You can change your list name</small-->
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label for="ListStatus">{{ $t('lstsettings_4')[0] }}</label>
									<select :class="['form-control', selectedList.status == '' ? 'is-invalid' : '']" 
									id="ListStatus" v-model="selectedList.status">
										<option value="" disabled>{{ $t('lstsettings_4')[1] }}</option>
										<option value="active">{{ $t('lstsettings_4')[2] }}</option>
										<option value="inactive">{{ $t('lstsettings_4')[3] }}</option>
									</select>
									<!--small class="form-text text-muted">Choose status</small-->
								</div>
							</div>
						</div>
						
						<div class="form-group row label-weight" v-if="isIsset(WebhookLink)">
							<div class="col-12">
								<label for="WebhookLink">{{ $t('lstsettings_5')[0] }}</label>
							</div>
							<div class="col-10 pr-0">
								<input type="text" class="form-control" disabled id="WebhookLink" :placeholder="$t('lstsettings_5')[1]" v-model.trim="WebhookLink" />
							</div>
							<div class="col-2 text-right">
								<button class="btn btn-primary btn-height btn-block" @click="CopyText(WebhookLink)">
									<i class="fa fa-clipboard"></i> {{ $t('lstsettings_5')[2] }}
								</button>
							</div>
						</div>
						
						<expand-content :open="Expand.mapping" :title="$t('lstsettings_6')[0]" icon="chevron">
							<template v-slot:content>
								<mapping-fields v-model="FieldsMapped"></mapping-fields>
							</template>
						</expand-content>
						
						<expand-content :open="Expand.notification" :title="$t('lstsettings_6')[1]" icon="chevron">
							<template v-slot:content>
								<div class="form-group label-weight">
									<div class="row">
										<div class="col-12 text-right">
											<check-box id="Notification" v-model="Notification.active" :label="$t('lstsettings_6')[2]"></check-box>
										</div>
									</div>
									<vue-tags v-model="Notification.recipients" :placeholder="$t('lstsettings_6')[3]"></vue-tags>
								</div>
							</template>
						</expand-content>
						
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { ExpandContent, VueTags, CheckBox, MappingFields } from '@/components' // , AutoComplete
	export default {
		name: 'ListSetting',
		data() {
			return {
				
				list_id		: '',
				Expand		: { mapping: true, notification: false },
				Notification: { active: true, recipients: [] },
				
				WebhookLink	: '',
				
				selectedList: { name: '', status: 'active' },
				
				FieldsMapped: [],
				
				Loading		: false
			}
		},
		components: {
			//
			ExpandContent,
			VueTags,
			CheckBox,
			MappingFields
		},
		methods: {
			SaveChanges() {
				let that 	 = this
				that.Loading = true
				
				let url  	= '/list/edit'
				let method  = 'POST'
				let data	= { list_id: that.list_id, details: that.selectedList, notification: that.Notification, MappingFields: that.FieldsMapped }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					let feedback = response.data
					if( feedback.status == 'success' ) {
						that.ToastNotify('success', 'Success', 'Changes saved')
						that.$router.push({name:'lists'})
					} else {
						that.ToastNotify('warning', 'Opps !', response.data.message)
					}
					that.Loading = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.Loading = false
				})
				
			},
			
			GetList() {
				let that	= this
				let url  	= '/list/' + that.list_id
				let method  = 'GET'
				let request = { url: url, method: method, params: {} }
				
				that.$store.dispatch('requests', request).then((response) => {
					let feedback = response.data
					if( feedback.status == 'success' ) {
						let list = feedback.list
						that.selectedList.name 	 = list.name
						that.selectedList.status = list.status
						that.WebhookLink		 = that.isIsset(feedback.webhooklink) ? feedback.webhooklink : ''
						that.FieldsMapped		 = feedback.mapping
						
						let lead_alert = list.lead_alert
						if( that.isIsset(list.lead_alert) ) {
							// console.log(lead_alert.recipients)
							that.Notification.active 	 = lead_alert.status == 'active'
							let recipients				 = that.isIsset(lead_alert.recipients) ? lead_alert.recipients : []
							that.Notification.recipients = recipients
							// console.log(recipients)
							// console.log(that.Notification)
						} else {
							that.Notification.recipients.push(that.$store.state.user.email)
						}
						
						that.$store.state.loading = false
					} else {
						that.$router.push({name:'lists'})
					}
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.$store.state.loading = false
				})
			}
		},
		mounted() {
			let that = this
			that.$store.state.loading = true
			that.list_id = that.isIsset(that.$route.params.list_id) ? that.$route.params.list_id : ''
			if( that.list_id != '' ) {
				that.GetList()
			}
		}
	}
</script>
<style lang="scss" scope>
	
</style>