<template>
	<div>
		
		<stepper :stepIn="stepIn" :steps="[$t('webhook_1')[0], $t('webhook_1')[1], $t('webhook_1')[2]]" color="blue"></stepper>
		
		<div class="col-12">
			<div class="row" v-show="stepIn == 'step1'">
				<div class="col-12 col-sm-10 col-md-8 col-lg-8 py-4 mx-auto">
					
					<div class="text-center">
						<h3>{{ $t('webhook_2')[0] }}</h3>
						<p>{{ $t('webhook_2')[1] }}</p>
					</div>
					<div class="form-group row label-weight">
						<div class="col-12">
							<label for="ListName">{{ $t('webhook_3')[0] }}</label>
						</div>
						<div class="col-9">
							<input type="text" :class="['form-control', isInvalid ? 'is-invalid' : '']" 
							id="ListName" :placeholder="$t('webhook_3')[1]" v-model.trim="ListName" />
						</div>
						<div class="col-3 text-right">
							<button class="btn btn-primary btn-height" @click="CreateList" :disabled="Processing">
								<i :class="['fa', Processing ? 'fa-cog fa-spin' : 'fa-cogs']"></i> {{ $t('webhook_3')[2] }}
							</button>
						</div>
						<div class="col-12" v-if="isInvalid">
							<small class="form-text text-danger">{{ $t('webhook_3')[3] }}</small>
						</div>
					</div>
					
				</div>
			</div>
			<div class="row" v-show="stepIn == 'step2'">
				<div class="col-12 col-sm-8 col-md-8 pt-4 pb-4 mx-auto">
					
					<div class="form-group row label-weight">
						<div class="col-12">
							<label for="WebhookLink">{{ $t('webhook_4')[0] }}</label>
						</div>
						<div class="col-10 pr-0">
							<input type="text" class="form-control" disabled id="WebhookLink" :placeholder="$t('webhook_4')[1]" v-model.trim="WebhookLink" />
						</div>
						<div class="col-2 text-right">
							<button class="btn btn-primary btn-height" @click="CopyText(WebhookLink)">
								<i class="fa fa-clipboard"></i> {{ $t('webhook_4')[2] }}
							</button>
						</div>
						
						<div class="col-12">
							<expand-content :open="Expand.info" :title="$t('webhook_5')[0]">
								<template v-slot:content>
									<div class="row">
										<div class="col-8">
											<ul class="instruction-list" style="list-style-type: decimal;">
												<li>{{ $t('webhook_5')[1] }}</li>
												<li v-html="$t('webhook_5')[2]"></li>
												<li>{{ $t('webhook_5')[3] }}</li>
												<li v-html="$t('webhook_5')[4]"></li>
											</ul>
										</div>
										<div class="col-4 text-right">
											<button class="btn btn-dark btn-height ml-2" @click="CheckWebhook()" :disabled="LoadingCheck">
												<i :class="['fa', LoadingCheck ? 'fa-spinner fa-spin' : 'fa-search']"></i> {{ $t('webhook_5')[5] }}
											</button>
										</div>
									</div>
								</template>
							</expand-content>
						</div>
						
						<div class="col-12">
							<expand-content :open="Expand.help" :title="$t('webhook_6')[0]">
								<template v-slot:content>
									<div class="row">
										<div class="col-12">
											<!-- CRM system -->
											<p v-html="$t('webhook_7', {name: ListName})"></p>
											<p class="mb-0">{{ $t('webhook_6')[1] }}</p>
										</div>
									</div>
								</template>
							</expand-content>
						</div>
						
					</div>
					
					<div class="row mt-3">
						<div class="col-6 my-auto">
							<button class="btn btn-dark" @click="NextStep('step1')">
								<i class="fa fa-chevron-left"></i> {{ $t('webhook_8')[0] }}
							</button>
						</div>
						<div class="col-6 text-right my-auto">
							<button class="btn btn-primary" v-if="WebhookCheck" @click="NextStep('step3')">
								{{ $t('webhook_8')[1] }} <i class="fa fa-chevron-right"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-show="stepIn == 'step3'">
				<div class="col-12 col-sm-8 col-md-8 pt-4 pb-4 mx-auto">
					
					<expand-content :open="Expand.mapping" :title="$t('webhook_9')[0]" icon="chevron">
						<template v-slot:content>
							
							<div class="form-group">
								<div class="row mb-3">
									<div class="col-4"><b>{{ $t('webhook_9')[1] }}</b></div>
									<div class="col-2 my-auto text-center"></div>
									<div class="col-6 pl-0"><b>{{ $t('webhook_9')[2] }}</b><!-- Field --></div>
								</div>
								<div class="row pb-2" v-for="(item, key) in MappingFields" v-bind:key="`field-${key}`">
									<div class="col-12" v-if="item.field == 'campaign_name'"><div class="border-top mb-2"></div></div>
									<div class="col-4" v-html="$t('webhook_10', {label: item.label})"></div>
									<div class="col-2 my-auto">
										<i class="fa fa-arrows-h fa-lg-md"></i>
										<!--i class="fa fa-long-arrow-right"></i-->
									</div>
									<div :class="[item.selected == 'custom_field' ? 'col-3' : 'col-6', 'pl-0']">
										<select class="form-control mapping-select" @change="selectMappingField(item)" v-model="item.selected">
											<option value=""> {{ $t('webhook_11')[0] }} </option>
											<option v-for="(option, id) in fieldsArray" 
											v-bind:key="`option-${id}`" 
											v-text="option.title" :value="option.field" 
											:disabled="option.selected && option.field != 'custom_field'"></option>
										</select>
									</div>
									<div class="col-3 pl-0" v-if="item.selected == 'custom_field'">
										<!--input type="text" class="form-control" placeholder="title field" v-model="item.custom" /-->
										<auto-complete :items="CustomFields" :placeholder="$t('webhook_11')[1]" @input="customInput(item)" v-model="item.custom" :defaultData="item.custom"></auto-complete>
									</div>
								</div>
							</div>
							
						</template>
					</expand-content>
					
					<expand-content :open="Expand.notification" :title="$t('webhook_12')[0]" icon="chevron">
						<template v-slot:content>
							<div class="form-group label-weight">
								<div class="row">
									<div class="col-12 text-right">
										<check-box id="Notification" v-model="Notification.active" :label="$t('webhook_12')[1]"></check-box>
									</div>
								</div>
								<vue-tags v-model="Notification.recipients" :placeholder="$t('webhook_12')[2]"></vue-tags>
							</div>
						</template>
					</expand-content>
					
					<!--expand-content :open="Expand.welcome_email" title="Welcome Email" icon="chevron">
						<template v-slot:content>
							<div class="form-group">
								asdasdas
							</div>
						</template>
					</expand-content-->
					
					<div class="row mt-3">
						<div class="col-6 my-auto">
							<button class="btn btn-dark" @click="NextStep('step2')">
								<i class="fa fa-chevron-left"></i> {{ $t('webhook_13')[0] }}
							</button>
						</div>
						<div class="col-6 text-right my-auto">
							<button class="btn btn-primary" @click="SaveSource()" :disabled="Processing" v-if="WebhookCheck">
								<i :class="['fa', Processing ? 'fa-cog fa-spin' : 'fa-save']"></i> {{ $t('webhook_13')[1] }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Stepper, ExpandContent, AutoComplete, VueTags, CheckBox } from '@/components'
	
	export default {
		name	: 'Webhook',
		components: {
			Stepper,
			AutoComplete,
			ExpandContent,
			
			VueTags,
			CheckBox
			
		},
		data() {
			return {
				
				// 
				stepIn			: 'step1',
				isInvalid		: false,
				
				ListName		: '',
				WebhookLink		: '',
				
				Expand			: { info: true, help: false, mapping: true, notification: true, welcome_email: false },
				Processing		: false,
				
				WebhookCheck	: false,
				LoadingCheck	: false,
				
				// ------------------------------------------------------------
				CustomFields	: [],
				MappingFields	: [],
				FieldsRequest	: [],
				fieldsArray	: [
					{ field: 'full_name',  	title: this.$t('webhook_14')[0], selected: false },
					{ field: 'first_name', 	title: this.$t('webhook_14')[1], selected: false },
					{ field: 'last_name',  	title: this.$t('webhook_14')[2], selected: false },
					{ field: 'email',     	title: this.$t('webhook_14')[3], selected: false },
					{ field: 'phone',     	title: this.$t('webhook_14')[4], selected: false },
					{ field: 'generated_at',title: this.$t('webhook_14')[5], selected: false },
					{ field: "custom_field",title: this.$t('webhook_14')[6], selected: false },
				],
				// ------------------------------------------------------------
				// {"email":"Doe@gmail.com","phone":"0988997799","last_name":"Doe","first_name":"John"}
				
				Notification	: { active: true, recipients: [] }
				// , WelcomeEmail: false
				
				
			}
		},
		mounted() {
			this.Notification.recipients.push(this.$store.state.user.email)
			this.GetConfig()
		},
		computed : {
			
		},
		methods: {
			// ------------------------------------------------------------
			
			GetConfig() {
				
				let that	= this
				let request = { url: '/fields', method: 'GET', params: {} }
				that.$store.state.loading = true
				that.$store.dispatch('requests', request).then((response) => {
					
					let CustomFields	= response.data.custom_fields
					that.CustomFields	= CustomFields
					that.$store.state.loading = false
					
				}).catch(( {response} ) => {
					that.$store.state.loading = false
					that.ErrorHandler(response)
				} )
				
			},
			
			selectMappingField(element) {
				if( element.oldValue != '' ) {
					this.fieldsArray.forEach( function(item) {
						if ( item.field == element.oldValue ) {
							item.selected = false
							item.oldValue = ''
						}
					} )
				}
				this.fieldsArray.forEach( function(item) {
					if( element.selected == item.field ) {
						item.selected = true
						element.oldValue = element.selected
					}
				} )
			},
			
			customInput(  ) { // item
				
			},
			
			AutoMapping() {
				
				let that = this, columns = [], fields = [], index = '';
				that.MappingFields = []
				let request   = Object.keys(that.FieldsRequest)
				request.forEach((field) => {
					index = field.toLowerCase()
					columns[index] = field
				})
				
				that.fieldsArray.forEach( function(col) {
					col.selected = false
				} )
				
				fields = Object.keys(columns)
				fields.forEach( function(item, id) {
					that.MappingFields.push({ field: item, label: columns[item], selected: '', oldValue: '', custom: item })
					let fieldValue = item.toLowerCase()
					
					that.fieldsArray.forEach( function(col) {
						
						if( !col.selected && col.field != "custom_field" ) {
							if( 
							fieldValue == col.field || 
							columns[item].toLowerCase() == col.field || 
							col.title.includes(columns[item].toLowerCase()) ||
							col.title.includes(columns[item]) ||
							col.title.includes(fieldValue.toLowerCase()) ||
							fieldValue.toLowerCase().includes(col.field)
							) {
								col.selected = true
								that.MappingFields[id].selected = col.field
								that.MappingFields[id].oldValue = col.field
							} else if ( 
								(fieldValue == 'name' && col.field == 'full_name')
								||  (fieldValue.includes('first') && fieldValue.includes('name') && col.field == 'first_name' )
								||  (fieldValue.includes('last') && fieldValue.includes('name') && col.field == 'last_name' )
							) {
								col.selected = true
								that.MappingFields[id].selected = col.field
								that.MappingFields[id].oldValue = col.field
							} else if ( fieldValue == 'created_time' ) {
								col.selected = true
								that.MappingFields[id].selected = 'generated_at'
								that.MappingFields[id].oldValue = 'generated_at'
							}
						}
						if( that.MappingFields[id].selected == '' ) {
							that.MappingFields[id].selected = 'custom_field'
						}
					} )
				} );
            },
			
			// ------------------------------------------------------------
			
			CheckSource() {
				let that 	= this
				let data 	= {}
				let request = { url: '/webhook/source', method: 'POST', data: data }
				that.$store.state.loading = true
				that.$store.dispatch('requests', request).then((response) => {
					let feedback = response.data
					if( that.isIsset(feedback.sourcelink) ) {
						that.ListName 	 = feedback.list_name
						that.WebhookLink = feedback.sourcelink
						that.NextStep('step2')
						that.CheckWebhook()
						return false
					}
					that.$store.state.loading = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.$store.state.loading = false
				} )
			},
			CreateList() {
				let that = this
				if( !that.isIsset(that.ListName) ) {
					that.isInvalid = true
					return false
				}
				that.Processing = true
				let data 		= { listname: that.ListName.trim() }
				let request 	= { url: '/webhook/store', method: 'POST', data: data }
				that.$store.dispatch('requests', request).then((response) => {
					if( response.data.status == 'success' ) {
						that.WebhookLink  = response.data.sourcelink
						
						that.NextStep('step2')
					} else {
						that.ToastNotify('warning', 'Opps !', response.data.message)
					}
					that.Processing = false
				}).catch(( {response} ) => {
					that.Processing = false
					that.ErrorHandler(response)
				} )
			},
			CheckWebhook() {
				let that 	= this
				let data 	= {}
				let request = { url: '/webhook/check', method: 'POST', data: data }
				that.LoadingCheck = true
				that.$store.dispatch('requests', request).then((response) => {
					let feedback = response.data
					if( feedback.status == 'success' ) {
						that.WebhookCheck = true
						that.FieldsRequest= feedback.request
						that.AutoMapping()
						that.NextStep('step3')
						that.$store.state.loading = false
					} else {
						that.$store.state.loading = false
						that.ToastNotify('warning', 'Opps!', feedback.message)
					}
					that.LoadingCheck = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.LoadingCheck = false
				})
			},
			SaveSource() {
				
				let that = this
				
				let stopProcess = true
				that.fieldsArray.forEach( function(col) {
					if( col.selected ) {
						stopProcess = false
					}
				} )
				if(stopProcess) {
					that.ToastNotify('warning', 'Mapping fields', 'Please map your fields')
					return false
				}
				
				that.Processing = true
				
				let url  	= '/webhook/save'
				let method  = 'POST'
				let data 	= { MappingFields: that.MappingFields, notification: that.Notification }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					let feedback = response.data
					if( feedback.status == 'success' ) {
						that.Processing = false
						that.$store.dispatch('SidebarCounter')
						that.$router.push({name: 'lists'})
					}
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.Processing = false
				} )
				
				
				
			},
			NextStep(step) {
				let that	= this
				that.stepIn	= step
			}
		},
		watch: {
			ListName(val) {
				let that = this
				if( that.isIsset(val) && that.isInvalid ) {
					that.isInvalid = false
				}
			},
			recipients: {
				handler(  ) { // val
					// console.log(val)
				},
				deep:true
			}
		},
		created() {
			this.CheckSource()
		}
	}
</script>

<style lang="scss">
	.instruction-list {
		list-style-type: decimal;
		padding-left: 25px;
		li {
			list-style-type: decimal;
		}
	}
</style>
