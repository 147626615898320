<template>
	<div class="container-fluid">
		<h4 class="c-grey-900 mT-10 mB-30">{{ $t('lstsources_1') }}</h4>
		<div class="row"><!-- gap-20 -->
			<div class="col-12 col-sm-4 col-md-3 mb-3" v-for="(item, key) in ArraySources" v-bind:key="`src-${key}`">
				<div class="layers bd bgc-white p-20">
					<div class="layer w-100 mB-10">
						<img :src="`/img/sources/${item.img}`" :alt="item.title" class="d-block mx-auto" style="max-height: 8em;" />
						<!--h6 class="lh-1" v-text="item.title"></h6-->
					</div>
					<div class="layer w-100">
						<div class="peers ai-sb fxw-nw">
							<div class="peer peer-greed my-auto">
								<h6 class="m-0" v-text="item.title"></h6>
							</div>
							<div class="peer">
								<router-link :to="{ path : `/source/${item.path}` }" class="btn btn-primary rounded-pill btn-sm"> <!--, params: {source: item.path} -->
									<i :class="[item.icon]"></i> {{ item.btnTitle }}
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Sources',
		data() {
			return {
				ArraySources: [
					{ id: 0, title: 'Facebook', 	img: 'Fb-Lead-Ads.png', icon: 'fa fa-cogs',   btnTitle: this.$t('lstsources_2')[0], path: 'facebook' },
					{ id: 1, title: 'Webhook-Api',	img: 'webhooks.png', 	icon: 'fa fa-cogs',   btnTitle: this.$t('lstsources_2')[1], path: 'webhook' },
					{ id: 2, title: 'XLS And CSV', 	img: 'Excel.png', 		icon: 'fa fa-upload', btnTitle: this.$t('lstsources_2')[2], path: 'csv' },
					{ id: 3, title: 'Text File', 	img: 'Txt-File.png', 	icon: 'fa fa-upload', btnTitle: this.$t('lstsources_2')[3], path: 'txt' },
					{ id: 4, title: 'Web Form',		img: 'WebForm.png', 	icon: 'fa fa-cogs',   btnTitle: this.$t('lstsources_2')[4], path: 'webform' },
				]
			}
		},
		methods: {
			
		},
		mounted() {
			let that = this
			that.$store.state.loading = false
			that.$store.state.selectedPage = that.$route.name
		},
		components: {
			//
		}
	}
</script>
<style lang="scss" scope>
	
</style>