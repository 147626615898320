<template>
	<div>
		
		<stepper :stepIn="stepIn" :steps="[$t('fbleadads_1')[0], $t('fbleadads_1')[1], $t('fbleadads_1')[2]]" color="blue"></stepper>
		
		<div class="col-12">
			<div class="row" v-show="stepIn == 'step1'">
				<div class="col-12 col-sm-10 col-md-8 col-lg-8 py-4 mx-auto">
					<div class="text-center">
						<h3>{{ $t('fbleadads_2')[0] }}</h3>
						<p>{{ $t('fbleadads_2')[1] }}</p>
						<facebook-login class="button" 
							app-id="2085028371622227" 
							@login="onLogin" 
							@logout="onLogout" 
							version="v10.0" 
							:disconnect="Disconnect"
							@get-init="GetInitial"
							:loginOptions="{ scope: 'pages_show_list,pages_read_engagement,leads_retrieval,ads_management,public_profile,pages_manage_ads,pages_manage_metadata,email', return_scopes : true }">
							<!-- need review => pages_read_engagement, ads_management -->
							<!-- @sdk-loaded="SdkLoaded" -->
						</facebook-login>
					</div>
					<div class="row mt-3">
						<div class="col-12 text-right my-auto" v-if="isConnected">
							<button class="btn btn-primary" @click="NextStep('step2')">{{ $t('fbleadads_2')[2] }} <i class="fa fa-chevron-right"></i></button>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-show="stepIn == 'step2'">
				<div class="col-12 col-sm-8 col-md-8 pt-4 pb-4 mx-auto">
					
					<div class="form-group row label-weight">
						<div class="col-4 my-auto">
							<label>{{ $t('fbleadads_3')[0] }}</label>
						</div>
						<div class="col-8">
							<!--select v-model="SelectedPage" @change="LoadForms" class="form-control">
								<option v-for="(page, key) in MyPages" v-bind:key="`page-${page.id}`" :value="key">{{ page.title }}</option>
							</select-->
							<drop-options v-model="SelectedPage" :options="PagesOption" :label="$t('fbleadads_3')[1]" @change="LoadForms" maxLength="48" class="w-100"></drop-options><!-- :icon="IconTeams" -->
							<small class="text-danger" v-if="page_empty">{{ $t('fbleadads_3')[2] }}</small>
						</div>
					</div>
					
					<div class="form-group row label-weight" v-show="!page_empty && isIsset(SelectedPage)">
						<div class="col-4 my-auto">
							<label>{{ $t('fbleadads_4')[0] }}</label>
						</div>
						<div class="col-8">
							<!--select v-model="SelectedForm" class="form-control" @change="ChooseForm">
								<option v-for="(form, key) in FormsOption" v-bind:key="`form-${form.id}`" :value="key">{{ form.title }}</option>
							</select-->
							<drop-options v-model="SelectedForm" :options="FormsOption" label="Select form" @change="ChooseForm" maxLength="45" class="w-100"></drop-options><!-- :icon="IconTeams" -->
							<small class="text-danger" v-if="form_exist == true" v-html="$t('fbleadads_4')[1]"></small>
						</div>
					</div>
					
					<div class="row mt-3">
						<div class="col-6 my-auto">
							<button class="btn btn-dark" @click="NextStep('step1')"><i class="fa fa-chevron-left"></i> {{ $t('fbleadads_4')[2] }}</button>
						</div>
						<div class="col-6 text-right my-auto">
							<button class="btn btn-primary" v-if="isIsset(SelectedForm) && form_exist == false" @click="NextStep('step3')">
								{{ $t('fbleadads_4')[3] }} <i class="fa fa-chevron-right"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-show="stepIn == 'step3'">
				<div class="col-12 col-sm-8 col-md-8 pt-4 pb-4 mx-auto">
					
					<expand-content :open="Expand.mapping" :title="$t('fbleadads_5')[0]" icon="chevron">
						<template v-slot:content>
							
							<div class="form-group">
								<div class="form-group label-weight">
									<label for="ListName">{{ $t('fbleadads_5')[1] }}</label>
									<input type="text" :class="['form-control', ListName.trim() == '' ? 'is-invalid' : '']" 
									id="ListName" :placeholder="$t('fbleadads_5')[2]" v-model.trim="ListName" />
									<!--small id="emailHelp" class="form-text text-muted">Your Can customize it as you like</small-->
								</div>
								<div class="row mb-3">
									<div class="col-4"><b>{{ $t('fbleadads_5')[3] }}</b></div>
									<div class="col-2 my-auto text-center"></div>
									<div class="col-6 pl-0"><b>{{ $t('fbleadads_5')[4] }}</b><!-- Field --></div>
								</div>
								<div class="row pb-2" v-for="(item, key) in MappingFields" v-bind:key="`field-${key}`">
									<div class="col-12" v-if="item.field == 'campaign_name'"><div class="border-top mb-2"></div></div>
									<div class="col-4" v-html="$t('fbleadads_6', {label: item.label})"></div>
									<div class="col-2 my-auto">
										<i class="fa fa-arrows-h fa-lg-md"></i>
										<!--i class="fa fa-long-arrow-right"></i-->
									</div>
									<div :class="[item.selected == 'custom_field' ? 'col-3' : 'col-6', 'pl-0']">
										<select class="form-control mapping-select" @change="selectMappingField(item)" v-model="item.selected">
											<option value=""> {{ $t('fbleadads_7')[0] }} </option>
											<option v-for="(option, id) in fieldsArray" 
											v-bind:key="`option-${id}`" 
											v-text="option.title" :value="option.field" 
											:disabled="option.selected && option.field != 'custom_field'"></option>
										</select>
									</div>
									<div class="col-3 pl-0" v-if="item.selected == 'custom_field'">
										<!--input type="text" class="form-control" placeholder="title field" v-model="item.custom" /-->
										<auto-complete :items="CustomFields" :placeholder="$t('fbleadads_7')[1]" @input="customInput(item)" v-model="item.custom" :defaultData="item.custom"></auto-complete>
									</div>
								</div>
							</div>
							
							<div class="row form-group" v-show="LeadsCount > 0">
								<div class="col-12 my-auto text-right">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="ImportLeads" v-model="ImportLeads">
										<label class="custom-control-label fw-600" for="ImportLeads" v-html="$t('fbleadads_8', {count: LeadsCount})"></label>
										<!-- @click="AutoRenew(entry)" -->
									</div>
								</div>
							</div>
							
						</template>
					</expand-content>
					
					<expand-content :open="Expand.notification" :title="$t('fbleadads_9')[0]" icon="chevron">
						<template v-slot:content>
							<div class="form-group label-weight">
								<div class="row">
									<div class="col-12 text-right">
										<check-box id="Notification" v-model="Notification.active" :label="$t('fbleadads_9')[1]"></check-box>
									</div>
								</div>
								<vue-tags v-model="Notification.recipients" :placeholder="$t('fbleadads_9')[2]"></vue-tags>
							</div>
						</template>
					</expand-content>
					
					<div class="row mt-4">
						<div class="col-6 my-auto">
							<button class="btn btn-dark" @click="NextStep('step2')"><i class="fa fa-chevron-left"></i> {{ $t('fbleadads_10')[0] }}</button>
						</div>
						<div class="col-6 text-right my-auto">
							<button class="btn btn-success" @click="SaveSource" :disabled="processing">
								<i :class="['fa', processing ? 'fa-cog fa-spin' : 'fa-cogs']"></i> {{ $t('fbleadads_10')[1] }}
							</button>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Stepper, DropOptions, AutoComplete, ExpandContent, VueTags, CheckBox } from '@/components'
	import FacebookLogin from '@/components/plugin/facebook-components'
	
	export default {
		name: 'FacebookLeadAds',
		components: {
			Stepper,
			AutoComplete,
			DropOptions,
			
			FacebookLogin,
			
			ExpandContent,
			VueTags,
			CheckBox
		},
		data() {
			return {
				// 
				stepIn		: 'step1',
				
				ListName	: '',
				processing	: false,
				fieldsArray	: [
					{ field: 'full_name',  	title: this.$t('fbleadads_11')[0], selected: false	},
					{ field: 'first_name', 	title: this.$t('fbleadads_11')[1], selected: false	},
					{ field: 'last_name',  	title: this.$t('fbleadads_11')[2], selected: false	},
					{ field: 'email',     	title: this.$t('fbleadads_11')[3], selected: false	},
					{ field: 'phone',     	title: this.$t('fbleadads_11')[4], selected: false	},
					{ field: 'generated_at',title: this.$t('fbleadads_11')[5], selected: false },
					{ field: "custom_field",title: this.$t('fbleadads_11')[6], selected: false },
				],
				
				CustomFields : [],
				MappingFields: [],
				
				isConnected	: false,
				Disconnect	: false,
				
				MyPages		: [],
				PagesOption	: [],
				SelectedPage: '',
				
				form_exist	: null,
				page_empty	: false,
				
				MyForms		: [],
				FormsOption	: [],
				SelectedForm: '',
				
				stepper		: 'step1',
				
				AuthResponse: [],
				AuthUser	: [],
				ImportLeads	: true,
				LeadsCount	: 0,
				
				Notification: { active: true, recipients: [] },
				Expand		: { mapping: true, notification: true }
				
			}
		},
		computed : {
			
		},
		methods: {
			
			AutoMapping() {
				
				let that = this, columns = [], fields = [], field = '';
				that.MappingFields = []
				let questions = that.MyForms[ that.SelectedForm ].questions
				questions.forEach((question) => {
					field = question.key.toLowerCase() // question.type.toLowerCase() - question.id
					columns[field] = question.label
				})
				
				columns['campaign_name'] = "Campaign Name"
				columns['ad_name'] 		 = "Ad Name"
				columns['adset_name'] 	 = "Adset Name"
				columns['created_time']  = "Created Time"
				columns['timezone'] 	 = "Timezone"
				
				that.fieldsArray.forEach( function(col) {
					col.selected = false
				} )
				
				fields = Object.keys(columns)
				fields.forEach( function(item, id) {
					that.MappingFields.push({ field: item, label: columns[item], selected: '', oldValue: '', custom: item })
					let fieldValue = item.toLowerCase()
					
					that.fieldsArray.forEach( function(col) {
						
						if( !col.selected && col.field != "custom_field" ) {
							if( 
							fieldValue == col.field || 
							columns[item].toLowerCase() == col.field || 
							col.title.includes(columns[item].toLowerCase()) ||
							col.title.includes(columns[item]) ||
							col.title.includes(fieldValue.toLowerCase()) ||
							fieldValue.toLowerCase().includes(col.field)
							) {
								col.selected = true
								that.MappingFields[id].selected = col.field
								that.MappingFields[id].oldValue = col.field
							} else if ( 
								(fieldValue == 'name' && col.field == 'full_name')
								||  (fieldValue.includes('first') && fieldValue.includes('name') && col.field == 'first_name' )
								||  (fieldValue.includes('last') && fieldValue.includes('name') && col.field == 'last_name' )
							) {
								col.selected = true
								that.MappingFields[id].selected = col.field
								that.MappingFields[id].oldValue = col.field
							} else if ( fieldValue == 'created_time' ) {
								col.selected = true
								that.MappingFields[id].selected = 'generated_at'
								that.MappingFields[id].oldValue = 'generated_at'
							}
						}
						if( that.MappingFields[id].selected == '' ) {
							that.MappingFields[id].selected = 'custom_field'
						}
						
					} )
					
				} );
				
            },
			
			ChooseForm() {
				let that 	  = this
				
				let pageid	= that.MyPages[that.SelectedPage].id
				let formid	= that.MyForms[that.SelectedForm].id
				that.LeadsCount		= that.MyForms[that.SelectedForm].leads_count
				
				that.ImportLeads 	= that.LeadsCount > 0
				
				let data 	= { pageid : pageid }
				let request = { url: '/leadsform/check/' + formid, method: 'GET', params: data }
				that.$store.state.loading = true
				that.$store.dispatch('requests', request).then((response) => {
					that.ListName = that.MyForms[that.SelectedForm].name
					that.$store.state.loading = false
					that.form_exist = response.data.exist
					if( !that.form_exist ) {
						that.NextStep('step3')
						that.AutoMapping()
					}
				}).catch(( {response} ) => {
					that.$store.state.loading = false
					that.ErrorHandler(response)
				} )
				
			},
			
			GetConfig() {
				
				let that 	  = this
				let request = { url: '/fields', method: 'GET', params: {} }
				that.$store.state.loading = true
				that.$store.dispatch('requests', request).then((response) => {
					
					let CustomFields	= response.data.custom_fields
					that.CustomFields	= CustomFields
					that.$store.state.loading = false
					
				}).catch(( {response} ) => {
					that.$store.state.loading = false
					that.ErrorHandler(response)
				} )
				
			},
			
			GetInitial(payload) {
				let that = this, access_token = ''
				
				that.MyPages		= []
				that.PagesOption	= []
				that.SelectedPage	= null
				that.page_empty		= false
				
				that.MyForms		= []
				that.FormsOption	= []
				that.SelectedForm	= null
				if(payload.status == 'connected') {
					
					that.isConnected = true
					that.NextStep('step2')
					access_token 	= payload.authResponse.accessToken
					let params		= { limit : 200, access_token: access_token };
					window.FB.api('/me', 'GET', params, { fields: 'id,name,email' }, (feedback) => {
						that.FacebookUserAuth(payload.authResponse, feedback)
					})
					window.FB.api('me/accounts', 'GET', params, (response) => {
						response.data.forEach( function(page, key) {
							that.MyPages.push(page)
							that.PagesOption.push({option: key.toString(), title: page.name, tag: ''})
						} )
					})
					
					// that.Disconnect = true
					
				} else {
					that.isConnected = false
				}
			},
			
			FacebookUserAuth(AuthResponse, AuthUser) {
				let that = this
				that.AuthResponse	= AuthResponse
				that.AuthUser		= AuthUser
				that.form_exist 	= null
				let data 	= { authResponse : that.AuthResponse, authUser: that.AuthUser }
				let request = { url: '/facebook/auth', method: 'POST', data: data }
				
				that.$store.dispatch('requests', request).then(() => { // response
					
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
				} )
				
			},
			
			async LoadForms() {
				let that = this, Forms = [], is_more = false
				
				that.MyForms		= []
				that.FormsOption	= []
				that.SelectedForm	= ''
				that.page_empty		= false
				that.form_exist 	= null
				
				that.$store.state.loading = true
				let legend_form_url = '/' + that.MyPages[ that.SelectedPage ].id + '/leadgen_forms?fields=id,name,created_time,leads_count,expired_leads_count,status,questions,locale&limit=15';
				do {
					const fbForms = await that.GetFBForms(legend_form_url,that.MyPages[ that.SelectedPage ].access_token);
					if(fbForms != false){
						fbForms.data.forEach(function(item) {
							Forms.push(item)
						})
						if (typeof fbForms.paging.next === 'undefined') {
							is_more = false;
						} else {
							is_more = true;
							legend_form_url = fbForms.paging.next;
						}
					}else{
						break;
					}

				} while (is_more);
				
				that.$store.state.loading = false
				Forms.forEach(function(form, id) {
					that.MyForms.push(form)
					that.FormsOption.push({ option: id.toString(), title: form.name, tag: that.TotalLeads(form.leads_count) })
				})
				
			},
			
			GetFBForms(legend_form_url,access_token) {
				
				let that = this
				if ( typeof legend_form_url === 'undefined' ) {
					return false;
				} else {
					return new Promise(function (resolve) {
						window.FB.api(legend_form_url, 'GET', { access_token: access_token }, (response) => {
							var resultArray = ''
							if( typeof response.data != 'undefined' ) {
								resultArray = response.data.join('')
							}
							if (resultArray == '' || resultArray.length === 0 ) {
								that.page_empty	= true
								resolve(false);
								return false;
							}else{
								resolve(response);
							}
							
						});
					});
				}
			},
			
			TotalLeads(leads_count) {
				let output  = '';
				if (typeof leads_count !== "undefined") {
					switch (leads_count) {
						case 0:
							output = '0 lead'; // No lead so far
							break;
						case 1:
							output = '1 lead';
							break;
						default:
							output = `${leads_count} Leads`;
					}
				}
				return output;
			},
			
			onLogin(response) {
				this.GetInitial(response)
			},
			
			onLogout(response) {
				this.GetInitial(response)
			},
			
			customInput(  ) { // item
				
			},
			
			selectMappingField(element) {
				if( element.oldValue != '' ) {
					this.fieldsArray.forEach( function(item) {
						if ( item.field == element.oldValue ) {
							item.selected = false
							item.oldValue = ''
						}
					} )
				}
				this.fieldsArray.forEach( function(item) {
					if( element.selected == item.field ) {
						item.selected = true
						element.oldValue = element.selected
					}
				} )
			},
			
			NextStep(step) {
				let that = this
				that.stepIn = step
			},
			
			SaveSource() {
				
				let that = this
				
				let stopProcess = true
				that.fieldsArray.forEach( function(col) {
					if( col.selected ) {
						stopProcess = false
					}
				} )
				if(stopProcess) {
					that.ToastNotify('warning', 'Mapping fields', 'Please map your fields')
					return false
				}
				if( that.ListName.trim() == "" ) {
					that.ToastNotify('warning', 'List name', 'List name cannot be empty')
					return false
				}
				that.processing = true
				
				let fbpage	= that.MyPages[that.SelectedPage]
				let fbform	= that.MyForms[that.SelectedForm]
				
				let url  	= '/leadsform/save'
				let method  = 'POST'
				let data 	= {
					listname	: that.ListName.trim(),
					fbpage		: fbpage,
					fbform		: fbform,
					authUser	: that.AuthUser,
					MappingFields: that.MappingFields,
					importleads	: that.ImportLeads,
					notification: that.Notification
				}
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => { // 
					
					let feedback = response.data
					if( feedback.status == 'success' ) {
						that.processing = false
						that.$store.dispatch('SidebarCounter')
						that.$router.push({name: 'leads'})
					}
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.processing = false
					
				} )
				
			}
			
		},
		mounted() {
			this.GetConfig()
			this.Notification.recipients.push(this.$store.state.user.email)
		}
	}
</script>

<style lang="scss">
	
</style>