<template>
	
	<div class="container-fluid">
		<!--h4 class="c-grey-900 mT-10 mB-30">My Lists</h4-->
		<div class="row mT-10 mB-30">
			<div class="col-6">
				<h4 class="c-grey-900 m-0">{{ $t('mylists_1')[0] }}</h4>
			</div>
			<div class="col-6 text-right">
				<router-link :to="{name: 'sources'}" class="btn btn-primary btn-sm">
					<i class="fa fa-plus"></i> {{ $t('mylists_1')[1] }}
				</router-link>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<div class="row form-group">
						<div class="col-3">
							<b>{{ $t('mylists_2')[0] }} </b>
							<select class="itemsPerPage-DataTable-strap hide-spin" v-model.number="itemsPerPage">
								<option disabled value="">{{ $t('mylists_2')[1] }}</option>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
						<div class="col-9">
							<!--h4 class="c-grey-900">Stats + search(by name, status, date)</h4-->
							<div class="form-inline pull-right">
								<div class="form-group"><!--  text-right -->
									<!--h4 class="c-grey-900">my lists</h4-->
									<multi-select :options="ListsOption" :selected="selectedLists" :label="$t('mylists_2')[2]" placeholderType="title" :placeholder="$t('mylists_2')[3]" 
									defaultSelected="all" :selectAll="true" multiple :caret="true" width="170px" @change="getLists()"></multi-select>
								</div>
								<div class="form-group ml-2">
									<multi-select :options="ListStatuses" :selected="selectedStatus" :label="$t('mylists_2')[4]" placeholderType="title" :placeholder="$t('mylists_2')[4]" 
									:selectAll="true" multiple :caret="true" width="170px" defaultSelected="all" @change="getLists()"></multi-select>
								</div>
								<div class="form-group ml-2">
									<date-picker right v-model="dateRange"></date-picker>
								</div>
							</div>
						</div>
					</div>
					
					<!--h4 class="c-grey-900 mB-20">Lists</h4-->
					<simple-table :dataItems="myLists" :columns="columns" @actionClick="actionClick" :footer="true"><!-- :responsive="true" -->
						<template v-slot:body="slotProps">
							<tr v-for="(entry, id) in slotProps.body" v-bind:key="id">
								<template v-for="(item, key) in columns">
									<td v-if="item.enable" v-bind:key="key">
										<div v-if="item.column == 'options'" class="text-center">
											<drop-down :items="columns">
												<template v-slot:label>
													<i class="fa fa-ellipsis-v c-pointer p-3 m-auto" style="font-size:18px;color: #2d2d2d;"></i>
													<!-- ti-settings -->
												</template>
												<template v-slot:options>
													<template v-for="option in optionsAction" >
														<li v-if="option.lists.includes(entry['type'])" v-bind:key="`li-${option.id}`" @click="actionClick(option.id, id, entry)">
															<a href="javascript:void(0);" class="d-b td-n p-5 bgcH-grey-100 c-grey-700">
																<i :class="[option.icon, 'm-5']"></i>
																<span>{{ option.label }}</span>
															</a>
														</li>
													</template>
												</template>
											</drop-down>
										</div>
										<div v-else-if="item.column == 'name'">
											<div class="d-flex">
												<div>
													<router-link :to="{path: `/leads/${entry['id']}`}" :title="entry[item.column]" class="text-dark">
														<div class="hover-underline">{{ max_length(entry[item.column], 36) }}</div>
													</router-link>
													<b class="fs-11">{{ $t('mylists_3') }} </b>
													<span style="color: rgb(121, 121, 121);">{{entry['createdAt'] | date_format}}</span>
												</div>
											</div>
										</div>
										<div v-else-if="item.column == 'status'" class="text-center">
											<span :class="statusBadge[entry[item.column]]">{{ entry[item.column] }}</span>
										</div>
										<div v-else-if="item.column == 'type'" class="text-center">
											<img :src="`/img/sources/${typeImages[entry['type']]}`" style="max-width: 3em; margin-right: 14px;">
										</div>
										<div v-else class="text-center">
											<b>{{ entry[item.column] }}</b>
										</div>
									</td>
								</template>
							</tr>
						</template>
					</simple-table>
					<div class="row">
						<div class="col-6 my-auto">
							<b v-if="totalLists > 0">{{ $t('mylists_4', {total: totalLists}) }}</b>
							<!-- Showing 1 to 10 of 57 entries -->
						</div>
						<div class="col-6 text-right">
							<pagination v-bind:lastPage="lastPage" v-on:page-changed="onPageChange" v-bind:current-page="currentPage" :maxVisiblePages="maxVisiblePages" />
						</div>
					</div>
					
					<modal v-show="modalEdit" @close="modalEdit = false" size="md" :title="$t('mylists_5')[0]">
						<template v-slot:body>
							
							<div class="card">
								<div class="card-header">
									<div class="row">
										<div class="col-md-4 my-auto">
											<img :src="`/img/sources/${typeImages[selectedList.type]}`" style="max-width: 2em;">
										</div>
										<div class="col-md-8 text-right my-auto">
											<h5 class="card-title m-0">{{ $t('mylists_5')[1] }} {{ selectedList.createdAt | date_format }}</h5>
										</div>
									</div>
								</div>
								<div class="card-body">
									<div class="row label-weight">
										<div class="col-6">
											<div class="form-group">
												<label for="ListName">{{ $t('mylists_5')[2] }}</label>
												<input type="text" :class="['form-control', selectedList.name == '' ? 'is-invalid' : '']" 
												id="ListName" :placeholder="$t('mylists_5')[3]" v-model="selectedList.name" />
												<!--small class="form-text text-muted">You can change your list name</small-->
											</div>
										</div>
										<div class="col-6">
											<div class="form-group">
												<label for="ListStatus">{{ $t('mylists_5')[4] }}</label>
												<select :class="['form-control', selectedList.status == '' ? 'is-invalid' : '']" 
												id="ListStatus" v-model="selectedList.status">
													<option value="" disabled>{{ $t('mylists_5')[5] }}</option>
													<option value="active">{{ $t('mylists_5')[6] }}</option>
													<option value="inactive">{{ $t('mylists_5')[7] }}</option>
												</select>
												<!--small class="form-text text-muted">Choose status</small-->
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						
						<template v-slot:footer>
							<div>
								<button type="button" class="btn btn-primary" @click="editList">
									<i class="fa fa-cog fa-spin" v-if="loading.editlist"></i> 
									<i class="ti-save mR-2 default-size" v-else></i> <span>{{ $t('mylists_5')[8] }}</span>
								</button>
							</div>
						</template>
					</modal>
					
					<modal size="confirm" v-show="ModalDelete" hide-header hide-footer>
						<template v-slot:body>
							<div class="text-center">
								<div>
									<svg xmlns="http://www.w3.org/2000/svg" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);width:6em; height:6em;" viewBox="0 0 512 512">
										<path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192z" fill="none" stroke="#ea1c0d" stroke-miterlimit="10" stroke-width="32"/>
										<path fill="none" stroke="#ea1c0d" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M320 320L192 192"/>
										<path fill="none" stroke="#ea1c0d" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M192 320l128-128"/>
									</svg>
								</div>
								<div>
									<h4>{{ $t('mylists_6')[0] }}</h4>
									<p class="m-0" style="font-size: 13px;">{{ $t('mylists_6')[1] }}</p>
									<h6>{{ selectedList.name }}</h6>
								</div>
								<div>
									<button class="btn btn-secondary mR-10" @click="ModalDelete = false">{{ $t('mylists_6')[2] }}</button>
									<button class="btn btn-danger" @click="deleteList" :disabled="loading.deletelist">
										<i class="fa fa-cog fa-spin" v-if="loading.deletelist"></i> 
										<i class="ti-trash mR-2 default-size" v-else></i> <span>{{ $t('mylists_6')[3] }}</span>
									</button>
								</div>
							</div>
						</template>
					</modal>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	import { SimpleTable, DropDown, Modal, DatePicker, MultiSelect, Pagination } from "@/components";
	export default {
		name: 'Lists',
		data() {
			return {
				// Pagination ----------------------------
				currentPage		: 1,
				lastPage		: 1,
				maxVisiblePages	: 3,
				itemsPerPage	: 5,
				totalLists		: 0,
				// Pagination ----------------------------
				
				
				dateRange	 : '',
				modalEdit	 : false,
				ModalDelete	 : false,
				
				
				ListsOption	 : [],
				selectedLists: [],
				ListStatuses : [],
				selectedStatus:[],
				
				// isInvalid 	 : { name: false, type: false },
				loading	 	 : { editlist: false, deletelist: false },
				columns		 : [
					{ title: this.$t('mylists_7')[0], column: 'id' 		  , enable: false, class: '' },
					{ title: this.$t('mylists_7')[1], column: 'name' 	  , enable: true , class: '' },
					{ title: this.$t('mylists_7')[2], column: 'status' 	  , enable: true , class: 'text-center' },
					{ title: this.$t('mylists_7')[3], column: 'type' 	  , enable: true , class: 'text-center' },
					{ title: this.$t('mylists_7')[4], column: 'totalLeads', enable: true , class: 'text-center' },
					{ title: this.$t('mylists_7')[5], column: 'qualified' , enable: true , class: 'text-center' },
					{ title: this.$t('mylists_7')[6], column: 'pending'   , enable: true , class: 'text-center' },
					{ title: this.$t('mylists_7')[7], column: 'options'   , enable: true , class: 'text-center' }
					
				],
				myLists		 : [],
				selectedList : { listId: '', id: '', name: '', createdAt: '', type: '', status: '' },
				optionsAction: [
					{id: 'edit',	label: this.$t('mylists_8')[0], icon: 'ti-pencil', lists: ['xlsx', 'txt', 'facebook', 'webhook', 'manual'] },
					{id: 'view',	label: this.$t('mylists_8')[1], icon: 'ti-view-list-alt', lists: ['xlsx', 'txt', 'facebook', 'webhook', 'manual'] },
					{id: 'setting',	label: this.$t('mylists_8')[2],	icon: 'ti-settings', lists: ['facebook', 'webhook'] },
					{id: 'delete',	label: this.$t('mylists_8')[3], icon: 'ti-trash', lists: ['xlsx', 'txt', 'facebook', 'webhook', 'manual'] }
					// {id: 2, label: 'Stats',		 	icon: 'ti-bar-chart-alt'},
					// {id: 3, label: 'Archived',		icon: 'ti-archive'},
				],
				statusBadge: {
					'active'   : 'badge badge-success badge-md',
					'inactive' : 'badge badge-danger badge-md'
				},
				typeImages: {
					'xlsx'		: 'Excel.png',
					'txt'		: 'Txt-File.png',
					'facebook'  : 'Facebook.png',
					'webhook'	: 'webhooks.png',
					'manual'	: 'manual.png'
				},
			}
		},
		methods: {
			
			// pagination ------------------
			onPageChange(page) {
				this.currentPage = page;
				this.getLists()
			},
			// pagination ------------------
			
			deleteList() {
				let that = this, ListsOption = []
				that.loading.deletelist = true
				
				let url  	= '/list/delete'
				let method  = 'delete'
				let data 	= { id:that.selectedList.id, type:that.selectedList.type }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then(() => {
					
					// Remove selected data
					that.ListsOption.forEach( function (item) {
						if( that.selectedList.id != item.id ) {
							ListsOption.push(item)
						}
					} )
					let index = that.selectedLists.indexOf(that.selectedList.id)
					if( index != -1 ) {
						that.selectedLists.splice(index, 1)
					}
					that.ListsOption.splice(0, that.ListsOption.length)
					ListsOption.forEach( function (item) {
						that.ListsOption.push(item)
					} )
					// Remove selected data
					
					that.loading.deletelist = false
					that.ModalDelete = false
					that.$store.dispatch('SidebarCounter')
				}).catch(({response}) => {
					that.ErrorHandler(response)
					that.getLists()
					that.loading.deletelist = false
					that.ModalDelete = false
				} )
				
			},
			editList() {
				let that = this
				if( that.selectedList.name != '' && Object.keys(that.statusBadge).includes(that.selectedList.status) ) {
					that.loading.editlist = true
					
					let url  	= '/list/update'
					let method  = 'PUT'
					let data 	= { id:that.selectedList.id, name:that.selectedList.name, status:that.selectedList.status, type:that.selectedList.type }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then(() => {
						
						that.loading.editlist = false
						that.getLists()
						that.modalEdit = false
						that.$store.dispatch('SidebarCounter')
						// that.myLists[that.selectedList.listId].name 	= that.selectedList.name
						// that.myLists[that.selectedList.listId].status = that.selectedList.status
						
					}).catch(({response}) => {
						that.ErrorHandler(response)
						that.loading.editlist = false
						that.modalEdit = false
					} )
					
				} else {
					that.ToastNotify('warning', 'Opps!', 'Fields required!')
				}
			},
			actionClick(action, listId, list) {
				let that = this
				
				that.selectedList.id 	= list.id
				that.selectedList.listId= listId
				that.selectedList.name 	= list.name
				that.selectedList.createdAt = list.createdAt
				that.selectedList.type 	= list.type
				that.selectedList.status= list.status
				
				if( action == 'edit' ) {
					that.modalEdit = true
				} else if( action == 'view' ) {
					that.$router.push({path:'leads/'+list.id})
				} else if( action == 'delete' ) {
					that.ModalDelete = true
				} else if( action == 'setting' ) {
					that.$router.push({path:'list/'+list.id})
				}
			},
			getLists() {
				
				let that = this
				let currentPage = that.currentPage
				
				if( that.selectedStatus.length == 0 || that.selectedLists.length == 0 ) {
					that.myLists = []
					return false
				}
				
				that.$store.state.loading = true
				
				let url  	= '/lists/get?page=' + currentPage
				let method  = 'POST'
				let data 	= {lists:that.selectedLists, status: that.selectedStatus, dateRange: that.dateRange, itemsPerPage:that.itemsPerPage}
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					that.myLists = []
					let feedback	 = response.data
					let lists  	 	 = feedback.data
					that.currentPage = feedback.current_page
					that.lastPage	 = feedback.last_page
					that.totalLists	 = feedback.total
					
					lists.forEach(function(list) {
						that.myLists.push( {
							id	 		: list.id,
							name 		: list.name,
							status 		: list.status,
							totalLeads 	: list.leads_count,
							qualified 	: list.qualified_count,
							pending 	: list.pending_count, // default status
							type 		: list.type,
							source 		: list.source,
							createdAt 	: list.created_at
						} )
					})
					
					that.$store.state.loading = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.$store.state.loading = false
				} )
				
			},
			
			getConfig() {
				// Only lists active ---------------------------------------------------------------------------------------
				let that = this
				that.$store.state.loading = true
				let url  	= '/lists/config'
				let method  = 'GET'
				let params 	= {}
				let request = { url: url, method: method, params: params }
				that.$store.dispatch('requests', request).then((response) => {
					that.ListsOption= []
					let lists  	 	= response.data.lists
					let statuses 	= JSON.parse( response.data.lead_statuses )
					lists.forEach(function(list) {
						that.ListsOption.push( { id : list.id, option : list.name, totalLeads : list.total } )
					})
					that.ListStatuses.push( { id : 'active', option : "Active" } )
					that.ListStatuses.push( { id : 'inactive', option : "Inactive" } )
					// <i class="fa fa-circle-o" v-if="MyStatuses[entry[item.column]]['color'] == '#ffffff'"></i> 
					// <i class="fa fa-circle" v-else :style="{color: MyStatuses[entry[item.column]]['color']}"></i> 
					that.columns.forEach( function(col) {
						if( ['qualified', 'pending'].includes(col.column) ) {
							
							Object.keys(statuses).forEach(function(status) {
								if( (statuses[status]['qualify'] && col.column == 'qualified') || ( statuses[status]['default'] && col.column == 'pending' ) ) {
									let cls   = statuses[status].color == '#ffffff' ? 'fa fa-circle-o' : 'fa fa-circle'
									let stl   = statuses[status].color == '#ffffff' ? '' : 'color:' + statuses[status].color
									col.title = '<i class="' + cls + ' default-size" style="' + stl + '"></i> ' + statuses[status].title
								}
							})
							
						}
					} )
					that.$store.state.loading = false
					that.getLists()
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.$store.state.loading = false
				} )
				
			},
			
		},
		mounted() {
			let that = this
			that.getConfig()
			that.$store.dispatch('SidebarCounter')
			that.$store.state.selectedPage = that.$route.name
		},
		components: {
			//
			SimpleTable,
			DropDown,
			Modal,
			DatePicker,
			MultiSelect,
			Pagination
		},
		watch: {
			dateRange: function() {
				this.getLists()
			},
			itemsPerPage: function(val) {
				let that = this
				if ( !isNaN(parseFloat(val)) && isFinite(val) && val != '' && val > 0 ) {
					// setTimeout(function() {
						if( val == that.itemsPerPage ) {
							that.currentPage = 1
							that.getLists()
						}
					// }, 1000)
				}
			},
		}
	}
</script>
<style lang="scss">
	
	.itemsPerPage-DataTable-strap {
		height: 35px;
		padding: 0.375rem 0.75rem;
		color: #495057;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		width: 85px;
	}
	
</style>