<template>
	<div class="container-fluid">
		<div class="alert alert-warning">
			{{ $t('srcwebforms_1') }}
		</div>
	</div>
</template>
<script>
	
	export default {
		name: 'WebForm',
		components: {
			//
		},
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		mounted() {
			
		},
		watch: {
			
		}
	}
</script>
<style lang="scss">
	
</style>