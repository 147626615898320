<template>
	<div class="container-fluid">
		<h4 class="c-grey-900 mT-10 mB-30">{{ $t('newsource_1') }}</h4>
		<div class="row">
			<div class="col-md-12">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<h4 class="c-grey-900 mB-20">
						<router-link :to="{ name : 'sources' }" :title="$t('newsource_2')">
							<i class="fa fa-arrow-circle-o-left cur-p"></i>
						</router-link> {{ $t('newsource_2') }}
					</h4>
					<template v-if="CurrentSource == 'csv'">
						<csv-import></csv-import>
					</template>
					<template v-else-if="CurrentSource == 'facebook'">
						<facebook-lead-ads></facebook-lead-ads>
					</template>
					<template v-else-if="CurrentSource == 'txt'">
						<txt-file></txt-file>
					</template>
					<template v-else-if="CurrentSource == 'webhook'">
						<web-hook></web-hook>
					</template>
					<template v-else-if="CurrentSource == 'webform'">
						<web-forms></web-forms>
					</template>
					
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import CsvImport 	from './Sources/CsvXLSX.vue'
	import TxtFile	 	from './Sources/TxtFile.vue'
	import FacebookLeadAds 	from './Sources/FacebookLeadAds.vue'
	import WebHook 		from './Sources/Webhook.vue'
	import WebForms		from './Sources/WebForms.vue'
	export default {
		name: 'NewSources',
		data() {
			return {
				CurrentSource: ''
			}
		},
		components: {
			//
			CsvImport,
			TxtFile,
			FacebookLeadAds,
			WebHook,
			WebForms
		},
		methods: {
			
		},
		mounted() {
			let that = this
			that.$store.state.loading = false
			that.$store.state.selectedPage = that.$route.name
			
			if( typeof that.$route.params.source != 'undefined' ) {
				that.CurrentSource = that.$route.params.source
			}
			
			if( !['csv', 'facebook', 'txt', 'webhook', 'webform'].includes(that.CurrentSource) ) {
				that.$router.push({ name: 'sources' })
			}
			
		}
	}
</script>
<style lang="scss" scope>
	
</style>