<template>
	<div>
		
		<stepper :stepIn="stepIn" :steps="[$t('txtfile_1')[0], $t('txtfile_1')[1], $t('txtfile_1')[2]]" color="blue"></stepper>
		
		<div class="col-12">
			<div class="row" v-if="stepIn == 'step1'">
				<div class="col-12 col-sm-8 col-md-6 pt-4 pb-4 mx-auto uploadWrapper">
					<div id="imageUploadForm" :class="['imageUploadForm pt-3 white', LoadingFile ? 'loading' : '', loadedFile ? 'loaded' : '']">
						<span class="helpText" id="helpText">{{ $t('txtfile_2')[0] }}</span>
						<input type="file" id="fileUpload" @change="selectFile" class="uploadButton" accept=".txt" />
						<div id="uploadedImg" class="uploadedImg" style="background-image:url('/img/sources/Txt-File.png');">
							<span class="unveil"></span>
						</div>
						<span class="pickFile">
							<button @click="uploadFile" class="btn btn-light btn-lg"><i class="fa fa-file-text-o"></i> {{ $t('txtfile_2')[1] }}</button>
						</span>
					</div>
					<div class="row mt-3" v-if="loadedFile">
						<div class="col-6 my-auto">
							<button class="btn btn-secondary" @click="nextStep('step1')">
								<i class="fa fa-undo"></i> {{ $t('txtfile_2')[2] }}
							</button>
						</div>
						<div class="col-6 text-right my-auto">
							<button class="btn btn-info" @click="nextStep('step2')">{{ $t('txtfile_2')[3] }} <i class="fa fa-chevron-right"></i></button>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="stepIn == 'step2'">
				<div class="col-12 col-sm-8 col-md-8 pt-4 pb-4 mx-auto">
					
					<div class="row form-group">
						<div class="col-4 my-auto"><b>{{ $t('txtfile_3')[0] }}</b></div>
						<div class="col-2 my-auto"><i class="fa fa-arrows-h fa-lg-md"></i></div>
						<div class="col-6">
							<input type="text" :class="['form-control', Setting.ListName.trim() == '' ? 'is-invalid' : '']" 
							id="ListName" :placeholder="$t('txtfile_3')[1]" v-model.trim="Setting.ListName" />
						</div>
					</div>
					
					<div class="row form-group">
						<div class="col-4 my-auto"><b>{{ $t('txtfile_4')[0] }}</b></div>
						<div class="col-2 my-auto"><i class="fa fa-arrows-h fa-lg-md"></i></div>
						<div class="col-6">
							<select class="form-control" v-model="Setting.Fields"><!-- mapping-select -->
								<option value=""> {{ $t('txtfile_4')[1] }} </option>
								<option v-for="(option, id) in FieldsArray" 
								v-bind:key="`option-${id}`" v-text="option.title" :value="option.field"></option>
							</select>
						</div>
					</div>
					<div class="row form-group" v-show="Setting.Fields">
						<div class="col-4 my-auto"><b v-html="$t('txtfile_5', {count:FileData.length, field: Setting.Fields})"></b></div>
						<div class="col-2 my-auto"><i class="fa fa-arrows-h fa-lg-md"></i></div>
						<div class="col-6 my-auto">
							<div class="custom-control custom-switch custom-switch-lg">
								<input type="checkbox" class="custom-control-input" id="RemoveDuplicate" v-model="Setting.Duplicate">
								<label class="custom-control-label" for="RemoveDuplicate"></label>
							</div>
						</div>
					</div>
					<div class="row form-group">
						<div class="col-4 my-auto"><b>{{ $t('txtfile_6')[0] }}</b></div>
						<div class="col-2 my-auto"><i class="fa fa-arrows-h fa-lg-md"></i></div>
						<div class="col-6 my-auto">
							<div class="custom-control custom-switch custom-switch-lg">
								<input type="checkbox" class="custom-control-input" id="FirstLine" v-model="Setting.FirstLine">
								<label class="custom-control-label" for="FirstLine"></label>
							</div>
						</div>
					</div>
					
					<div class="row mt-3">
						<div class="col-6 my-auto">
							<button class="btn btn-dark" @click="nextStep('step1')"><i class="fa fa-chevron-left"></i> {{ $t('txtfile_6')[1] }}</button>
						</div>
						<div class="col-6 text-right my-auto">
							<button class="btn btn-success" @click="SaveFile()" :disabled="Setting.Fields == '' || Setting.ListName == '' || processing">
								<i :class="['fa', processing ? 'fa-cog fa-spin' : 'fa-cogs']"></i> {{ $t('txtfile_6')[2] }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="stepIn == 'step3'">
				<div class="col-12 col-sm-8 col-md-8 pt-4 pb-4 mx-auto">
					<div class="alert alert-success" role="alert">
						<h5 class="alert-heading">{{ $t('txtfile_7')[0] }}</h5>
						<p class="m-0">{{ $t('txtfile_7')[1] }}</p>
						<!--hr>
						<small class="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</small-->
					</div>
					<div class="text-center">
						<!--router-link :to="{name: 'dashboard'}"><i class="fa fa-bar-chart"></i> Dashboard</router-link>
						<span> | </span-->
						<router-link :to="{name: 'leads'}"><i class="fa fa-angle-left"></i> {{ $t('txtfile_7')[2] }}</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Stepper } from '@/components'
	
	export default {
		name: 'TxtFile',
		components: {
			Stepper
		},
		mounted() {
			document.getElementById("uploadedImg").addEventListener('animationend', function() {} );
		},
		data() {
			return {
				// 
				LoadingFile	: false,
				loadedFile	: false,
				stepIn		: 'step1',
				
				FileInfo	: {},
				Setting		: { ListName: '', Fields: '', Duplicate: false, FirstLine: false },
				processing	: false,
				
				FieldsArray	: [
					{ field: 'email', title: this.$t('txtfile_8')[0], selected: false },
					{ field: 'phone', title: this.$t('txtfile_8')[1], selected: false },
				],
				
				FileData	: [],
				
			}
		},
		computed : {
			
		},
		methods: {
			
			uploadFile() {
				document.getElementById("fileUpload").click()
			},
			
			nextStep(step) {
				let that = this
				if( step == 'step1' ) {
					that.LoadingFile = false
					that.loadedFile  = false
					setTimeout( function() {
						document.getElementById('fileUpload').value= null;
					}, 1000 )
				}
				that.stepIn = step
			},
			
			selectFile(e) {
				let that 	= this
				var files 	= e.target.files;
				
				that.LoadingFile  = true
				
				that.FileInfo 	 	  = files[0]
				that.Setting.ListName = that.FileInfo.name
				
				var reader = new FileReader();
				reader.onload = function(e) {
					that.FileData = e.target.result;
				};
				
				setTimeout( function() {
					that.loadedFile = true
				}, 3500 )
				reader.readAsText(that.FileInfo);
				// reader.readAsDataURL(that.FileInfo)
				// reader.readAsBinaryString(fileImport);
				// reader.readAsArrayBuffer(that.FileInfo);
			},
			
			SaveFile() {
				let that = this
				
				if(that.Setting.Fields == '') {
					that.ToastNotify('warning', 'Choose Your Field', 'Select Your File Items.')
					return false
				}
				
				if( that.Setting.ListName.trim() != "" ) {
					that.processing = true
					
					let FileInfo = []
					
					FileInfo.push({
						name			: that.FileInfo.name,
						size			: that.FileInfo.size,
						type			: that.FileInfo.type,
						lastModified	: that.FileInfo.lastModified,
						lastModifiedDate: that.FileInfo.lastModifiedDate,
					})
					// webkitRelativePath: ""
					
					let url  	= 'txt/save'
					let method  = 'POST'
					let data 	= {
						list_name	: that.Setting.ListName.trim(),
						fields		: that.Setting.Fields,
						duplicate	: that.Setting.Duplicate,
						first_line	: that.Setting.FirstLine,
						file_info	: FileInfo,
						file_data	: that.FileData
					}
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => { // response
						
						if( response.data.status == 'error' ) {
							that.ToastNotify('warning', 'Opps!', response.data.message)
						} else {
							that.stepIn 	= 'step3'
						}
						that.processing = false
						that.$store.dispatch('SidebarCounter')
					}).catch(( {response} ) => {
						that.ErrorHandler(response)
						that.processing = false
						that.stepIn 	= 'step3'
					} )
				} else {
					that.ToastNotify('warning', 'Opps!', 'List name cannot be empty!')
				}
				
			}
			
		},
		created() {
			
		}
	}
</script>

<style lang="scss">
	
</style>